<template>
  <div class="container">
    <div class="card" v-bind:class="{flipped: flipped}">
      <div class="cardface front">
        <div class="fullscreenimage unlit"/>
        <div class="fullscreenimage lit" v-bind:class="{visible: lit}"/>
        <div class="merrychristmas" v-bind:class="{visible: merryxmas}">
          <h1>Merry Christmas</h1>
          <h2>With love from Chippenham</h2>
        </div>
      </div>
      <div class="cardface back">
        <!--<div class="fullscreenimage hidden" v-bind:class="{visible: exterior_index == id}" v-bind:style="{ 'background-image': 'url(' + photo + ')'}" v-for="(photo, id) in exterior" v-bind:key="id"></div>-->
        <div class="message">
          <div>
            <h3>{{ message }}</h3>
          </div>
          <div>
            <h2>Merry Christmas</h2>
            <h2>and a</h2>
            <h2>Happy New Year</h2>
          </div>
          <div>
            <img src="~@/assets/family.jpg"/>
            <h3>With love from</h3>
            <h3>Joseph, Sarah and Isobel</h3>
          </div>
        </div>
      </div>
    
    </div>
    
  </div>
</template>

<script>
//import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  data: function() {
    return {
      lit: false,
      merryxmas: false,
      delay: 2500,
      count: 0,
      flipped: false,
      exterior_pics: 12,
      exterior_index: 0,
      months: [
        ["January", "We started the year in full planning mode, getting ready for work on our extension to start"],
        ["February", "February was full of highs and lows - Isy's first go at climbing, the tree came down and we all got Covid"],
        ["March", "In March the building work started, Lion King"],
        ["April", "Stood for town council, clan gathering, Easter"]
      ],
      recipients: {
        '4c300bda-3350-425c-b3ba-74777b8bfea0': 'Mary and Tony',
        '6cd4eab2-f66f-4299-b8e4-dbe00ff9ebc6': 'Margaret and Robin',
        '96d1567a-035f-48ae-8873-63b057e01ef6': 'Phyllis and David',
        'd61edf5c-3970-47dd-ba9a-483734e21ddd': 'Paul, Katie and Monty',
        'f329473c-d84d-4d79-b55c-57fe21064960': 'James and Victoria',
        '98a2905e-c125-494c-ad69-44da6e5fd656': 'Olivia, Alex, Milo and Aubrey'
      }
    }
  },
  computed: {
    exterior: function() {
      var exterior_photos = [];
      for (var i=1; i<this.exterior_pics; i++) {
        var url = '@/assets/exterior/exteriorstack'+i+'.jpg';
        exterior_photos.push(this.getImage(url));
      }
      return exterior_photos;
    },
    message: function() {
      let params = new URLSearchParams(window.location.search);
      var person = params.get('q');
      if (person != '' && this.recipients[person] != undefined) {
        return 'To '+this.recipients[person];
      } else {
        return '';
      }
    }
  },
  mounted: function() {
    this.animator();
  },
  components: {
    //HelloWorld,
  },
  methods: {
    getImage: function(url) {
      return require(url);
    },
    animator: function() {
      var ch = this;

      if (this.count >= 1) {
        this.lit = !this.lit;
      }

      if (this.count >= 3) {
        setTimeout(function() {
          ch.merryxmas = true;
        }, ch.delay/2)
        
      }

      if (this.count >= 5) {
        this.flipped = true;
      }

      if (this.count >= 7) {
        console.log(this.exterior_index);
        this.exterior_index += 1;
      }

      setTimeout(function() {
        ch.count += 1;
        ch.animator();
      }, ch.delay)
    }
  }
};
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Crimson+Pro:wght@200;400;800&display=swap');

$border-radius: 10px;

#app {
  font-family: 'Crimson Pro', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333333;
}

h1 {
  font-family: 'Berkshire Swash', cursive;
}

h1, h2 {
  margin: 0;
}

h2 {
  font-weight: 400;
}

.container {
  position: fixed;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  perspective: 1200px;
  border-radius: $border-radius;

  .card {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 3s;
    transform-style: preserve-3d;
    transform-origin: center right;
    border-radius: $border-radius;

    &.flipped {
      transform: translateX(-100%) rotateY(-180deg);
    }
  }

  .cardface {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: $border-radius;

    &.back {
      background-image: url('~@/assets/paper.jpeg');
      background-size: 30%;
      transform: rotateY( 180deg );
    }
  }
}

.fullscreenimage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: $border-radius;

  &.unlit {
    background-image: url('~@/assets/unlit.jpg');
  }

  &.lit {
    background-image: url('~@/assets/lit.jpg');
    opacity: 0;
    transition: 2s;
  }

  &.hidden {
    opacity: 0;
    transition: 2s;
  }

  &.visible {
    opacity: 1;
  }
}

.merrychristmas {
  opacity: 0;
  transition: 2s;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  

  h2 {
    transition-delay: 4s;
    transition: 2s;
    opacity: 0;
  }

  h1, h2 {
    color: white;
    text-align: center;
    text-shadow: 1px 1px 4px #000000;
  }

  &.visible {
    opacity: 1;

    h2 {
      opacity: 1;
    }
  }

  h1 {
    font-size: 3em;
  }

  
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 1 auto;
  }

  h2, h3 {
    margin: 0;
    text-align: center;
  }
  img {
    height: 200px;
    border-radius: 50%;
    flex: 0 1 auto;
    margin-bottom: 20px;
  }
}
</style>
